/* eslint-disable react/no-unescaped-entities */
import Card from '../../components/common/card';
import { useAppDispatch, useAppSelector } from '../../utils/reduxHooks';
import { useState } from 'react';
import { CompanyDetails, ShopDetailsType } from '../../ts-types/custom.types';
import Button from '../../components/ui/button';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import Input from 'src/components/ui/input';
import SwitchInput from 'src/components/ui/switch-input';
import { getActiveShopDetails, updateShopDetails } from 'src/store/shop/shopsAPIActions';
import { toast } from 'react-toastify';
import { RootState } from 'src/store';
import { getShopDetails } from 'src/utils/shopUtils';
import { TOAST_MESSAGE } from 'src/utils/constants';

type FormValues = {
  companyID: string;
  apiKey: string;
  sendInvoiceByEmail: boolean;
  supportsCreditCard: boolean;
  supportsCash: boolean;
  maxNumberOfInstallments: number;
};

export default function PaymentPage() {
  const { t }: any = useTranslation();
  const shopDetails: ShopDetailsType =
    useAppSelector((state: RootState) => state.shop.shopDetails?.activeShopDetailsResponse?.data) ||
    {};
  const {
    Officeguy,
    supportsCreditCard = false,
    supportsCash = false,
  } = shopDetails?.companyDetails || {};
  const dispatch = useAppDispatch();
  const [sendByEmail, setSendByEmail] = useState<boolean>(Officeguy?.sendInvoiceByEmail ?? false);
  const [supportsCreditCardST, setSupportsCreditCardST] = useState<boolean>(supportsCreditCard);
  const [supportsCashST, setSupportsCashST] = useState<boolean>(supportsCash);
  const [openTab, setOpenTab] = useState(1);

  const [loading, setLoading] = useState<boolean>(false);

  const methods = useForm<FormValues>({
    shouldUnregister: true,
    mode: 'onChange',
    defaultValues: {
      companyID: Officeguy?.companyID,
      apiKey: Officeguy?.apiKey,
      sendInvoiceByEmail: Officeguy?.sendInvoiceByEmail,
      supportsCreditCard: supportsCreditCardST,
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = methods;

  const onSubmit = (values: FormValues) => {
    const shopSettings = { ...shopDetails };
    const companyDetails: CompanyDetails = shopSettings.companyDetails ?? {};
    companyDetails.supportsCreditCard = values.supportsCreditCard;
    companyDetails.supportsCash = values.supportsCash;
    companyDetails.maxNumberOfInstallments = values.maxNumberOfInstallments;
    const { shopId } = getShopDetails();
    companyDetails.shopId = shopId;
    companyDetails.Officeguy = { ...values };
    shopSettings.companyDetails = companyDetails;
    dispatch(updateShopDetails(shopSettings))
      .then((response: any) => {
        toast.success(t(TOAST_MESSAGE.COMPANY_INFO_UPDATED_SUCCESSFULLY));
        dispatch(getActiveShopDetails());
        setLoading(false);
      })
      .catch((err: any) => {
        toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
        setLoading(false);
      });
  };

  return (
    <Card withShadow>
      <div className='mb-4  border-gray-200 dark:border-gray-700'>
        <ul
          className='flex flex-wrap -mb-px text-sm font-medium text-center border-b '
          id='default-tab'
          data-tabs-toggle='#default-tab-content'
          role='tablist'
        >
          <li className='me-2' role='presentation'>
            <button
              className={
                'inline-block p-4 border-b-2 rounded-t-lg  ' +
                (openTab === 1 ? 'text-white bg-accent' : 'text-accent bg-white')
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(1);
              }}
              id='profile-tab'
              data-tabs-target='#profile'
              type='button'
              role='tab'
              aria-controls='profile'
              aria-selected='false'
            >
              UPay
            </button>
          </li>
          <li className='me-2' role='presentation'>
            <button
              className={
                'inline-block p-4 border-b-2 rounded-t-lg  ' +
                (openTab === 2 ? 'text-white bg-accent' : 'text-accent bg-white')
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(2);
              }}
              id='dashboard-tab'
              data-tabs-target='#dashboard'
              type='button'
              role='tab'
              aria-controls='dashboard'
              aria-selected='false'
            >
              PayPal
            </button>
          </li>
          <li className='me-2' role='presentation'>
            <button
              className={
                'inline-block p-4 border-b-2 rounded-t-lg  ' +
                (openTab === 3 ? 'text-white bg-accent' : 'text-accent bg-white')
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(3);
              }}
              id='dashboard-tab'
              data-tabs-target='#dashboard'
              type='button'
              role='tab'
              aria-controls='dashboard'
              aria-selected='false'
            >
              Options
            </button>
          </li>
        </ul>
        <div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-6  rounded'>
          <div className='px-4 py-5 flex-auto'>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className='tab-content tab-space'>
                  <div className={openTab === 1 ? 'block' : 'hidden'} id='link1'>
                    <Card
                      className=''
                      title={`${t('payment-details-title')} UPay`}
                      withShadow={false}
                      titleClassName='text-lg font-semibold text-heading'
                    >
                      <div className='w-full items-center'>
                        <Input
                          label={t('input-label-companyID')}
                          {...register('companyID')}
                          type='text'
                          name='companyID'
                          variant='outline'
                          className='mb-4'
                          error={t(errors?.companyID?.message)}
                        />
                        <Input
                          label={t('input-label-apiKey')}
                          {...register('apiKey')}
                          type='text'
                          name='apiKey'
                          variant='outline'
                          className='mb-4'
                          error={t(errors?.apiKey?.message)}
                        />
                        <Button type='submit' className='ml-5 mt-5'>
                          {t('form-save')}
                        </Button>
                      </div>
                    </Card>
                  </div>
                  <div className={openTab === 2 ? 'block' : 'hidden'} id='link2'>
                    <Card
                      className=''
                      title={`${t('payment-details-title')} PayPal`}
                      withShadow={false}
                      titleClassName='text-lg font-semibold text-heading'
                    >
                      <div className='w-full items-center'>
                        {/*  <Input
                          label={t('input-label-companyID')}
                          {...register('companyID')}
                          type='text'
                          variant='outline'
                          className='mb-4'
                          error={t(errors?.companyID?.message)}
                        />
                        <Input
                          label={t('input-label-apiKey')}
                          {...register('apiKey')}
                          type='text'
                          variant='outline'
                          className='mb-4'
                          error={t(errors?.companyID?.message)}
                        />
                        <Button type='submit' className='ml-5 mt-5'>
                          {t('form-save')}
                        </Button>*/}
                      </div>
                    </Card>
                  </div>
                  <div className={openTab === 3 ? 'block' : 'hidden'} id='link3'>
                    <Card
                      className=''
                      title={t('settings')}
                      withShadow={false}
                      titleClassName='text-lg font-semibold text-heading'
                    >
                      <div className='w-full items-center'>
                        <div className='pt-4'>
                          <SwitchInput
                            label={t('input-label-send-invoice-by-email')}
                            name='sendInvoiceByEmail'
                            onChange={(val) => setSendByEmail(val)}
                            setValue={setValue}
                            value={getValues('sendInvoiceByEmail')}
                            defaultValue={sendByEmail}
                          />
                        </div>
                        <div className='pt-4'>
                          <SwitchInput
                            label={t('input-label-send-supportsCreditCard')}
                            name='supportsCreditCard'
                            onChange={(val) => setSupportsCreditCardST(val)}
                            setValue={setValue}
                            value={getValues('supportsCreditCard')}
                            defaultValue={supportsCreditCardST}
                          />
                        </div>
                        <div className='pt-4'>
                          <SwitchInput
                            label={t('input-label-send-supportsCash')}
                            name='supportsCash'
                            onChange={(val) => setSupportsCashST(val)}
                            setValue={setValue}
                            value={getValues('supportsCash')}
                            defaultValue={supportsCashST}
                          />
                        </div>
                        <div className='pt-4'>
                          <Input
                            label={t('input-label-send-maxNumberOfInstallments')}
                            name='maxNumberOfInstallments'
                            onChange={(val) =>
                              setValue('maxNumberOfInstallments', Number(val.target.value))
                            }
                            type='number'
                            value={getValues('maxNumberOfInstallments')}
                          />
                        </div>
                        <Button type='submit' className='ml-5 mt-5'>
                          {t('form-save')}
                        </Button>
                      </div>
                    </Card>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </Card>
  );
}
