import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import LoginPage from 'src/pages/auth/Login';
import AdminContactUsPage from 'src/pages/adminContactUsPage';
import RegisterPage from 'src/pages/auth/Register';
import { UIProvider } from 'src/contexts/ui.context';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { store, persistor } from './store/index';

import './App.css';
import { ROUTES } from './utils/routes';
import ManagedModal from './components/ui/modal/managed-modal';
import { TermsAndConditions } from './pages/termsAndPolicy/TermsAndConditions';
import { PrivacyAndPolicy } from './pages/termsAndPolicy/PrivacyAndPolicy';
import AnonymousCreateShop from './pages/anonymousCreateShop/index';
import BusinessDetailsPage from './pages/anonymousCreateShop/businessDetailsPage';

import ShopLayout from './components/layouts/shop';
import MetaPixel from './utils/metaPixel';
if (process.env.NODE_ENV === 'development') {
  const originalConsoleError = console.error;
  console.error = (...args) => {
    if (typeof args[0] === 'string' && args[0].includes('ResizeObserver loop limit exceeded')) {
      return;
    }
    console.log(...args);
  };
}
const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MetaPixel />
        <UIProvider>
          <BrowserRouter>
            <Switch>
              <Route path={`${ROUTES.LOGIN}`} component={LoginPage} />
              <Route exact path={ROUTES.REGISTER} component={RegisterPage} />
              <Route exact path={ROUTES.ADMIN_CONTACT_US} component={AdminContactUsPage} />
              <Route exact path={ROUTES.ANONY_CREATE_SHOP} component={AnonymousCreateShop} />
              <Route
                exact
                path={ROUTES.ANONY_CREATE_SHOP_LAST_STEP}
                component={BusinessDetailsPage}
              />
              <Route exact path={ROUTES.TERMS_AND_CONDITION} component={TermsAndConditions} />
              <Route exact path={ROUTES.PRIVACY_POLICY} component={PrivacyAndPolicy} />
              <Route
                path={[ROUTES.SHOPS, ROUTES.ENTRY]}
                render={(props) => <ShopLayout {...props} />}
              />
            </Switch>
            <ManagedModal />
          </BrowserRouter>
        </UIProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
