import { ProductType } from './generated';

export enum LANGUAGE {
  HEBREW = 'HEBREW',
  ARABIC = 'ARABIC',
}
export enum SAVE_MODE {
  SAVE = 'SAVE',
  EDIT = 'EDIT',
  NONE = 'NONE',
}
export enum SHOP_STATUS {
  RUNNING = 'RUNNING',
  IN_PROGRESS = 'IN_PROGRESS',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  NOT_STARTED = 'NOT_STARTED',
  CANCELED = 'CANCELED',
  TRAILER = 'TRAILER',
  EXPIRED = 'EXPIRED',
  TRAILER_EXPIRED = 'TRAILER_EXPIRED',
}
export enum PATH {
  PRODUCTS = 'products',
  POPULAR_PRODUCTS = 'popular-products',
  CATEGORIES = 'categories',
  ORDERS = 'orders',
  ORDER_STATUS = 'order_status',
  COUPONS = 'coupons',
  USERS = 'users',
  ME = 'me',
  TOKEN = 'token',
  REGISTER = 'register',
  CHECKOUT = 'checkout/verify',
  ADDRESS = 'address',
  TYPE = 'types',
  CHANGE_PASSWORD = 'change-password',
  FORGET_PASSWORD = 'forget-password',
  VERIFY_FORGET_PASSWORD_TOKEN = 'verify-forget-password-token',
  RESET_PASSWORD = 'reset-password',
  LOGOUT = 'logout',
  ANALYTICS = 'analytics',
  CUSTOMERS = 'customers',
  SETTINGS = 'settings',
  ATTACHMENTS = 'attachments',
  TAXES = 'taxes',
  SHIPPING = 'shipping',
  BLOCK_USER = 'users/ban-user',
  UNBLOCK_USER = 'users/active-user',
}

export const PAGE_SIZE = 10;

export enum UPLOAD_SECTION {
  PRODUCTS = 'products',
  SITE_LOGO = 'site-logo',
  SITE_MAIN_IMAGE = 'site-main-image',
  CATEGORY = 'category',
  PLUGIN = 'plugin',
}

export enum LIMIT {
  TEN = 10,
}

export enum PRODUCT_STATUS {
  PUBLISH = 'publish',
  DRAFT = 'draft',
}

export enum SHOP_TYPE {
  GROCERY = 'grocery',
  MAKEUP = 'makeup',
  BAGS = 'bags',
  BOOK = 'book',
  MEDICINE = 'medicine',
  FURNITURE = 'furniture',
  CLOTHING = 'clothing',
  BAKERY = 'bakery',
}

export enum SORT_TYPE {
  ASC = 'asc',
  DESC = 'desc',
}
export enum SEARCH_JOIN {
  AND = 'and',
  OR = 'or',
}

export enum OrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at',
}

export enum ProductCardTypeEnum {
  NEON = 'neon',
  HELIUM = 'helium',
  ARGON = 'argon',
  KRYPTON = 'krypton',
  XENON = 'xenon',
  RADON = 'radon',
}

export enum SiteTemplateEnum {
  Classic = 'classic',
  Modern = 'modern',
  Standard = 'standard',
  Minimal = 'minimal',
}

export enum ShopUserRoles {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  EMPLOYEE = 'EMPLOYEE',
}

export enum BusinessTypeEnum {
  Fashion = 'Fashion',
  Accessories = 'Accessories',
  Food = 'Food',
  Electronics = 'Electronics',
  ArtsBooks = 'ArtsBooks',
  KidsBabies = 'KidsBabies',
  PetsSndAnimals = 'PetsSndAnimals',
  Other = 'Other',
}
export enum ORDER_STATUS {
  IN_PREPARATION = 'in_preparation',
  PENDING_SHIPPING = 'pending_shipping',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  FAILED = 'failed',
}

export enum ORDER_STATUS_PROCESS {
  IN_PREPARATION = 'in_preparation',
  PENDING_SHIPPING = 'pending_shipping',
  COMPLETED = 'completed',
}
export const productTypeArray = [
  { id: 1, name: 'simple-product', value: ProductType.Simple },
  { id: 2, name: 'variant-product', value: ProductType.Variable },
];
