import { useState } from 'react';
import { Table } from 'src/components/ui/table';
import { siteSettings } from 'src/settings/site.settings';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'src/ts-types/generated';
import TitleWithSort from 'src/components/ui/title-with-sort';
import { ShopDetailsType } from 'src/ts-types/custom.types';
import { Link, useHistory } from 'react-router-dom';
import { ROUTES } from 'src/utils/routes';
import ActionButtons from '../common/action-buttons';
import { renderShopStatus } from 'src/utils/shopUtils';
import ShopActionButton from './shopActionButton';
import _ from 'lodash';
import { useAppDispatch } from 'src/utils/reduxHooks';
import { clearShopState } from 'src/store/shop/actions';

type IProps = {
  shops?: Array<ShopDetailsType>;
  onPagination: (current: number) => void;
  onSort: (current: any) => void;
  onOrder: (current: string) => void;
};

const ShopList = ({ shops, onPagination, onSort, onOrder }: IProps) => {
  const data = shops! ?? {};
  const history = useHistory();
  const { t }: any = useTranslation<any>();
  const dispatch = useAppDispatch();

  if (!data) return null;
  const [sortingObj, setSortingObj] = useState<{
    sort: SortOrder;
    column: string | null;
  }>({
    sort: SortOrder.Desc,
    column: null,
  });

  const onHeaderClick = (column: string | null) => ({
    onClick: () => {
      onSort((currentSortDirection: SortOrder) =>
        currentSortDirection === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc,
      );
      onOrder(column!);

      setSortingObj({
        sort: sortingObj.sort === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc,
        column: column,
      });
    },
  });

  const columns = [
    {
      title: t('table-item-logo'),
      dataIndex: 'logoUrl',
      key: 'logoUrl',
      align: 'right',
      width: 74,
      render: (logo: any, record: any) => (
        <img
          src={(logo || _.isEmpty(logo)) ?? siteSettings.product.placeholder}
          alt={record?.name}
          width={42}
          height={42}
          className='rounded overflow-hidden'
        />
      ),
    },
    {
      title: (
        <TitleWithSort
          title={t('table-item-title')}
          ascending={sortingObj.sort === SortOrder.Asc && sortingObj.column === 'name'}
          isActive={sortingObj.column === 'name'}
        />
      ),
      dataIndex: 'website',
      key: 'website',
      width: 100,
      align: 'right',
      onHeaderCell: () => onHeaderClick('name'),
      render: (website: any, { shopId }: any) => (
        <Link to={`${ROUTES.ACTIVE_SHOP}${shopId}`}>
          <span className='whitespace-nowrap'>{website}</span>
        </Link>
      ),
    },
    {
      title: (
        <TitleWithSort
          title={t('shop-status')}
          ascending={sortingObj.sort === SortOrder.Asc && sortingObj.column === 'shopCurrentStatus'}
          isActive={sortingObj.column === 'shopCurrentStatus'}
        />
      ),
      dataIndex: 'shopCurrentStatus',
      key: 'shopCurrentStatus',
      align: 'center',
      onHeaderCell: () => onHeaderClick('shopCurrentStatus'),
      render: (website: any, { shopCurrentStatus, shopId }: any) => (
        <div>{renderShopStatus(shopCurrentStatus)}</div>
      ),
    },
    {
      title: t('table-item-actions'),
      dataIndex: 'id',
      key: 'actions',
      width: 260,
      align: 'right',
      render: (website: any, { shopCurrentStatus, shopId }: any) => (
        <div onClick={(e) => e.stopPropagation()}>
          <ActionButtons
            id={shopId || ''}
            editUrl={`${ROUTES.ACTIVE_SHOP}${shopId}`}
            deleteModalView='DELETE_SHOP'
          />
          <ShopActionButton className='mr-4' currentStatus={shopCurrentStatus} shopId={shopId} />
        </div>
      ),
    },
  ];

  const onRowClick = (record: { shopId: any }) => {
    dispatch(clearShopState());
    history.push(`${ROUTES.ACTIVE_SHOP}${record.shopId}`);
  };

  return (
    <>
      <div className='rounded overflow-hidden shadow mb-6'>
        <Table
          // @ts-ignore
          columns={columns}
          className='cursor_table'
          rowClassName={() => 'cursor-pointer'}
          onRow={(record, index) => ({
            // @ts-ignore
            onClick: onRowClick.bind(null, record, index),
            style: {
              cursor: 'pointer',
            },
          })}
          emptyText={t('empty-table-data')}
          data={data}
          rowKey='shopId'
          scroll={{ x: 800 }}
        />
      </div>
    </>
  );
};

export default ShopList;
