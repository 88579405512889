import { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { logPageView } from 'src/ga';
import { AUTH_CRED } from 'src/utils/constants';
import { ROUTES } from 'src/utils/routes';

const PrivateRoute = (props: any) => {
  const token = localStorage?.getItem(AUTH_CRED);
  useEffect(() => {
    logPageView();
    console.log('window.location.pathname', window.location.pathname);
  }, []);
  return <>{token ? <Route {...props} /> : <Redirect to={ROUTES.LOGIN} />}</>;
};

export default PrivateRoute;
