import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { productValidationSchema } from './product-validation-schema';
import { useAppSelector, useAppDispatch } from '../../utils/reduxHooks';
import { useTranslation } from 'react-i18next';
import Alert from 'src/components/ui/alert';
import { useEffect, useState } from 'react';
import { animateScroll } from 'react-scroll';
import {
  convertToTranslatedObject,
  getDiscountPrice,
  getEndDiscountDate,
  getPrice,
  getProductDetails,
} from 'src/utils/productUtils';
import { ProductFormValues, VariantDetailsList } from 'src/ts-types/custom.types';
import { setActiveProduct } from 'src/store/shop/actions';
import {
  updateProduct,
  createProduct,
  getAllAddOnsCategories,
} from 'src/store/shop/shopsAPIActions';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/utils/routes';
import { getImages } from 'src/utils/categoryUtils';
import { toast } from 'react-toastify';
import Stepper from '../stepper';
import ProductBasicInfo from './productBasicInfo';
import ProductVariantInfo from './productVariantInfo';
import { SAVE_MODE } from 'src/ts-types';
import { RootState } from 'src/store';
import { TOAST_MESSAGE } from 'src/utils/constants';
import { ProductType } from 'src/ts-types/generated';

export default function CreateOrUpdateProductForm() {
  const history = useHistory();
  const { t }: any = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const dispatch = useAppDispatch();
  const submitIsLoading = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.updateProductResponse?.isFetching,
  );
  const actionType = useAppSelector((state: RootState) => state.shop.shopDetails?.actionType);

  useEffect(() => {
    dispatch(getAllAddOnsCategories({}));
  }, []);

  const activeProduct = useAppSelector((state: RootState) => state.shop.shopDetails?.activeProduct);
  const productVariants = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.productVariants,
  );

  const translatedObject = convertToTranslatedObject(activeProduct?.productInfoWithTranslations);

  const methods = useForm<ProductFormValues>({
    resolver: yupResolver(productValidationSchema),
    mode: 'onBlur',
    defaultValues: {
      image: getImages(activeProduct?.mainImage),
      categoryId: activeProduct?.categoryId,
      productCode: activeProduct?.productCode,
      mainImage: activeProduct?.mainImage,
      moreImages: activeProduct?.moreImages,
      productSEO: activeProduct?.productSEO,
      variantType: activeProduct?.variantType,
      addOnsCategories: activeProduct?.addOnsCategories,
      productType: activeProduct?.productType ?? ProductType.Simple,
      productMilli: activeProduct?.productMilli,
      price: getPrice(activeProduct!) ?? 0,
      discountPrice: getDiscountPrice(activeProduct!),
      endDiscountDate: getEndDiscountDate(activeProduct!),
      shortDescription: translatedObject.shortDescription,
      description: translatedObject.description,
      name: translatedObject.name,
      topProduct: activeProduct?.topProduct,
      hotProduct: activeProduct?.hotProduct,
      active: actionType === SAVE_MODE.SAVE ? true : activeProduct?.active,
      variantDetailsList: activeProduct?.variantDetailsList,
    },
  });
  const {
    handleSubmit,
    control,
    trigger,
    setValue,
    getValues,
    register,
    setError,
    watch,
    formState: { errors },
  } = methods;

  const getCurrentProduct = (formValues?: ProductFormValues, isSubmitCall?: boolean) => {
    const values = { ...activeProduct, ...formValues };
    return getProductDetails(
      { ...values, ...getValues(), variantDetailsList: productVariants ?? [] },
      isSubmitCall,
    );
  };

  const onSubmit = async (values: ProductFormValues) => {
    const isSubmitCall = true;
    const inputValues = getCurrentProduct(values, isSubmitCall);
    if (actionType === SAVE_MODE.EDIT) {
      delete inputValues.name;
      dispatch(
        updateProduct({
          ...inputValues,
          productId: activeProduct?.productId,
        }),
      )
        .then((res: any) => {
          toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
          history.push(ROUTES.PRODUCTS);
        })
        .catch((error: { response: { data: { [x: string]: any[]; message?: any } } }) => {
          if (error?.response?.data?.message) {
            setErrorMessage(error?.response?.data?.message);
            animateScroll.scrollToTop();
          } else {
            Object.keys(error?.response?.data).forEach((field: any) => {
              setError(field, {
                type: 'manual',
                message: error?.response?.data[field][0],
              });
            });
          }
          toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
        });
    } else {
      dispatch(createProduct(inputValues))
        .then((res: any) => {
          toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
          history.push(ROUTES.PRODUCTS);
        })
        .catch((error: { response: { data: { [x: string]: any[]; message?: any } } }) => {
          if (error?.response?.data?.message) {
            setErrorMessage(error?.response?.data?.message);
            animateScroll.scrollToTop();
          } else {
            Object.keys(error?.response?.data).forEach((field: any) => {
              setError(field, {
                type: 'manual',
                message: error?.response?.data[field][0],
              });
            });
          }
          toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
        });
    }
  };
  useEffect(() => {
    setValue('categoryId', activeProduct?.categoryId || '');
  }, [activeProduct?.categoryId, setValue]);

  function validateProductBasicDetails(): Promise<boolean> {
    return new Promise((resolve) => {
      trigger(['status', 'name', 'categoryId', 'productCode']).then(
        (res: boolean | PromiseLike<boolean>) => resolve(res),
      );
    });
  }

  const handleUpdateProduct = () => {
    dispatch(setActiveProduct(getCurrentProduct(), actionType));
  };

  const steps = [
    {
      target: '.step1prod',
      content: t('step1prod'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step2prod',
      content: t('step2prod'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step3prod',
      content: t('step3prod'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step4prod',
      content: t('step4prod'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step5prod',
      content: t('step5prod'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step6prod',
      content: t('step6prod'),
      locale: {
        last: 'סיום',
        next: 'סיום', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step7prod',
      content: t('step7prod'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step8prod',
      content: t('step8prod'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step9prod',
      content: t('step9prod'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step10prod',
      content: t('step10prod'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step11prod',
      content: t('step11prod'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step12prod',
      content: t('step12prod'),
      locale: {
        last: 'סיום',
        next: 'סיום', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
  ];
  [
    {
      target: '.tourSelector',
      content: 'This is the coolest feature on this page',
      title: 'You can have a title here!',
      styles: {
        // this styles override the styles in the props
        options: {
          textColor: 'tomato',
        },
      },
      locale: {
        next: <span>GO GO GO</span>,
        back: <span>BACK BACK</span>,
      },
      placement: 'top',
    },
  ];
  return (
    <>
      {errorMessage ? (
        <Alert
          message={t(`${errorMessage}`)}
          variant='error'
          closeable={true}
          className='mt-5'
          onClose={() => setErrorMessage(null)}
        />
      ) : null}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stepper
            updateSelectedStep={(val) => setCurrentStep(val)}
            submitButtonIsLoading={submitIsLoading}
            submitButtonText={activeProduct ? 'update' : 'submit-button'}
            steps={[
              {
                element: () => (
                  <ProductBasicInfo
                    show={0 === currentStep}
                    register={register}
                    errors={errors}
                    getValues={getValues}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                  />
                ),
                isNextDisabled: async () => await validateProductBasicDetails(),
              },
              {
                element: () => (
                  <ProductVariantInfo
                    show={1 === currentStep}
                    register={register}
                    updateCurrentProduct={handleUpdateProduct}
                    errors={errors}
                    setValue={setValue}
                    control={undefined}
                    trigger={trigger}
                    getValues={getValues}
                    setProductDiscount={(val: any) => setValue('discountPrice', val)}
                    watch={watch}
                  />
                ),
                isNextDisabled: () => true,
                hasSubmitButton: true,
              },
            ]}
          />
        </form>
      </FormProvider>
    </>
  );
}
