import { useForm, useFieldArray } from 'react-hook-form';
import Button from 'src/components/ui/button';
import { useAppDispatch } from '../../utils/reduxHooks';
import Card from 'src/components/common/card';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddOnCategoryValidationSchema } from './addon-category-validation-schema';
import { useTranslation } from 'react-i18next';
import { addNewAddOnCategoryAction, updateAddOnCategoryAction } from 'src/store/shop/actions';
import {
  AddOn,
  AddOnCategory,
  AddOnCategoryInput,
  TranslationObject,
} from 'src/ts-types/custom.types';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/utils/routes';
import { useState } from 'react';
import { toast } from 'react-toastify';
import InputWithTranslation from '../ui/inputWithTranslation';
import Input from '../ui/input';
import SwitchInput from '../ui/switch-input';
import { TOAST_MESSAGE } from 'src/utils/constants';
import UnControlledFileInput from '../ui/unControlled-file-input';
import { UPLOAD_SECTION } from 'src/ts-types';

type FormValues = {
  addOnCategoryId: string;
  titleWithTeanslations: TranslationObject[];
  maxAddonsToSelect: number;
  active: boolean;
  addOnsList: AddOn[];
};

type IProps = {
  initialValues?: AddOnCategory | null;
};

export default function CreateOrUpdateAddOnCategoriesForm({ initialValues }: IProps) {
  const { t }: any = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [isActive, setIsActive] = useState(true);
  const [isAddOnActive, setAddOnIsActive] = useState(true);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      addOnCategoryId: initialValues?.addOnCategoryId,
      titleWithTeanslations: initialValues?.titleWithTeanslations,
      maxAddonsToSelect: initialValues?.maxAddonsToSelect,
      active: initialValues?.active,
      addOnsList: initialValues?.addOnsList || [],
    },
    resolver: yupResolver(AddOnCategoryValidationSchema),
  });

  // UseFieldArray to handle the addOnsList array
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'addOnsList',
  });

  // Function to handle adding new add-on
  const addNewAddOn = () => {
    append({
      addOnId: '',
      nameWithTeanslations: [{ value: '' }],
      image: '',
      price: 0,
      active: true,
    });
  };

  function onSubmit(values: FormValues) {
    const input: AddOnCategoryInput = {
      addOnCategoryId: values?.addOnCategoryId,
      titleWithTeanslations: values.titleWithTeanslations,
      maxAddonsToSelect: values.maxAddonsToSelect,
      active: values.active,
      addOnsList: values?.addOnsList,
    };

    if (initialValues) {
      dispatch(updateAddOnCategoryAction(input)).then((res: any) => {
        toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
        history.push(ROUTES.ADDONS_CATEGORIES);
      });
    } else {
      delete input.addOnCategoryId;
      input.addOnsList?.map((addOn: AddOn) => delete addOn.addOnId);
      dispatch(addNewAddOnCategoryAction(input)).then((res: any) => {
        toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
        history.push(ROUTES.ADDONS_CATEGORIES);
      });
    }
  }

  const updateStatus = async (status: boolean) => {
    setIsActive(status);
    setValue('active', status);
  };

  const updateAddOnStatus = async (status: boolean, index: number) => {
    setAddOnIsActive(status);
    setValue(`addOnsList.${index}.active`, status);
  };

  const renderCategoriesAddOnTitle = () => {
    return (
      <div className='flex justify-between items-center'>
        {/* Title */}
        <div className='font-bold'> {t('form-title-create-addons-category')}</div>

        {/* Icons container */}
        <div className='flex items-center'>
          {/* Plus Icon */}
          <div className='flex justify-start items-center mt-4'>
            <Button type='submit' className='ml-5 '>
              {initialValues
                ? t('button-label-update-addons-category')
                : t('button-label-add-addons-category')}
            </Button>
            <Button variant='outline' onClick={() => history.push(ROUTES.ADDONS_CATEGORIES)}>
              {t('form-return')}
            </Button>
          </div>
        </div>
      </div>
    );
  };
  const renderPlusAndTrashIcons = () => {
    return (
      <div className='flex justify-between items-center'>
        {/* Title */}
        <div className='font-bold'>{t('add-new-addon')}</div>

        {/* Icons container */}
        <div className='flex items-center'>
          {/* Plus Icon */}
          <div className='flex justify-start items-center mt-4'>
            <Button
              type='button'
              variant='outline'
              onClick={addNewAddOn}
              className='flex items-center text-primary focus:outline-none'
            >
              {t('button-label-add')} +
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card className='w-full' title={renderCategoriesAddOnTitle()}>
        <div className='w-full md:w-2/3'>
          <Input
            tabIndex={1}
            label={t('input-label-addon-category-id')}
            variant='outline'
            {...register('addOnCategoryId')}
            error={t(errors?.addOnCategoryId?.message)}
          />
          <InputWithTranslation
            label={t('input-label-name')}
            error={t(errors.titleWithTeanslations?.message)}
            variant='outline'
            required
            values={getValues('titleWithTeanslations')}
            setValue={(val: any) => setValue('titleWithTeanslations', val)}
            name='titleWithTeanslations'
          />
          <Input
            tabIndex={1}
            label={t('input-label-max-addon-to-select-number')}
            variant='outline'
            {...register('maxAddonsToSelect')}
            error={t(errors?.maxAddonsToSelect?.message)}
          />
          <SwitchInput
            label={t('table-item-status')}
            name='active'
            className='pb-2 pl-6 pt-4'
            onChange={(val) => updateStatus(val)}
            defaultValue={isActive}
            value={isActive}
          />
        </div>
      </Card>

      <Card className='w-full mt-4' title={renderPlusAndTrashIcons()}>
        <div className='w-full md:w-2/3'>
          {fields.map((item, index) => (
            <div key={item.id} className='relative mb-4'>
              <Input
                tabIndex={1}
                label={t('input-label-addon-category-id')}
                variant='outline'
                {...register(`addOnsList.${index}.addOnId`)}
                error={t(errors?.addOnsList?.[index]?.addOnId?.message)}
              />
              <InputWithTranslation
                label={t('input-label-name')}
                error={t(errors.addOnsList?.[index]?.nameWithTeanslations?.message)}
                variant='outline'
                required
                values={getValues(`addOnsList.${index}.nameWithTeanslations`)}
                setValue={(val: any) => setValue(`addOnsList.${index}.nameWithTeanslations`, val)}
                name={`addOnsList.${index}.nameWithTeanslations`}
              />
              <UnControlledFileInput
                withCrop
                croppedMaxWidth={180}
                croppedMaxHeight={120}
                multiple={false}
                title={t('addon_image')}
                classContainer='mt-2'
                onImageUploaded={(val: any) => setValue(`addOnsList.${index}.image`, val)}
                defaultValue={getValues(`addOnsList.${index}.image`)}
                uploadSection={UPLOAD_SECTION.CATEGORY}
              />
              <Input
                tabIndex={1}
                label={t('input-label-price')}
                variant='outline'
                {...register(`addOnsList.${index}.price`)}
                error={t(errors?.addOnsList?.[index]?.price?.message)}
              />
              <SwitchInput
                label={t('table-item-status')}
                name={`addOnsList.${index}.active`}
                className='pb-2 pl-6 pt-4'
                onChange={(val) => updateAddOnStatus(val, index)}
                defaultValue={isAddOnActive}
                value={isAddOnActive}
              />
              <Button
                onClick={() => remove(index)}
                className='flex items-center justify-center rounded-full bg-red-600 hover:bg-red-500 shadow-xl outline-none'
              >
                {t('button-label-remove')}
              </Button>
            </div>
          ))}
        </div>
      </Card>
    </form>
  );
}
