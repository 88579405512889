import _ from 'lodash';
import { faPeopleCarryBox, faCreditCardAlt } from '@fortawesome/free-solid-svg-icons';
import NoShopAlert from 'src/components/shop/noShopAlert';
import UpgradePackageAlert from 'src/components/shop/upgradePackageAlert';
import Badge from 'src/components/ui/badge/badge';
import { ORDER_STATUS, SHOP_STATUS } from 'src/ts-types';
import { OrderStatus, ShopDetailsType, ShopStatus } from 'src/ts-types/custom.types';
import { SHOP_ID, SHOP_CURRENT_STATUS } from './constants';
import { isSuperAdmin } from './authUtils';
import { format } from 'date-fns';
import ShopInProgressAlert from 'src/components/shop/shopInProgressAlert';

export const SUCCESS_STYLE = '';
export const WARRNING_STYLE = '';

export enum CREATE_SHOP_FLOW {
  SHORT = 'short',
  FULL = 'full',
}

export function setShopId(shopId?: string) {
  localStorage?.setItem(SHOP_ID, shopId ?? '');
}

export function setShopStatus(shopCurrentStatus?: ShopStatus) {
  localStorage?.setItem(SHOP_CURRENT_STATUS, shopCurrentStatus || '');
}

export function getShopDetails(): any {
  return {
    shopId: localStorage?.getItem(SHOP_ID),
    shopStatus: localStorage?.getItem(SHOP_CURRENT_STATUS),
  };
}
export function renderShopExpirationDate(expirationDate = '') {
  if (!_.isEmpty(expirationDate)) {
    const date = format(new Date(expirationDate ?? ''), 'uuuu-MM-dd');
    const currentDate = new Date();
    const givenDate = new Date(expirationDate ?? '');

    if (currentDate < givenDate) {
      return (
        <Badge
          textKey={date}
          className='text-accent width-fit text-md font-semibold text-heading '
        />
      );
    } else {
      return (
        <Badge
          textKey={date}
          className='bg-red-400 text-white width-fit text-md font-semibold text-heading '
        />
      );
    }
  }
}

export function renderShopStatus(shopStatus?: ShopStatus) {
  switch (shopStatus) {
    case SHOP_STATUS.RUNNING:
      return (
        <Badge
          textKey='RUNNING'
          className='text-accent width-fit text-md font-semibold text-heading '
        />
      );
    case SHOP_STATUS.CANCELED:
      return (
        <Badge
          textKey='CANCELED'
          className='bg-red-400 text-white width-fit text-md font-semibold text-heading '
        />
      );
    case SHOP_STATUS.EXPIRED:
      return (
        <Badge
          textKey='EXPIRED'
          className='bg-red-400 text-white width-fit text-md font-semibold text-heading '
        />
      );
    case SHOP_STATUS.IN_PROGRESS:
      return (
        <Badge
          textKey='IN_PROGRESS'
          className='bg-blue-500  width-fit text-md font-semibold text-heading '
        />
      );
    case SHOP_STATUS.NOT_STARTED:
      return (
        <Badge
          textKey='NOT_STARTED'
          className='bg-yellow-100 text-yellow-400 width-fit text-md font-semibold text-heading '
        />
      );
    case SHOP_STATUS.PAYMENT_FAILED:
      return (
        <Badge
          textKey='PAYMENT_FAILED'
          className='bg-red-400 text-white width-fit text-md font-semibold text-heading '
        />
      );
    case SHOP_STATUS.TRAILER_EXPIRED:
      return (
        <Badge
          textKey='TRAILER_EXPIRED'
          className='bg-yellow-100 text-yellow-400 width-fit underline width-fit text-md font-semibold text-heading '
        />
      );
    case SHOP_STATUS.TRAILER:
      return (
        <Badge
          textKey='TRAILER'
          className='bg-blue-500 width-fit text-md font-semibold text-heading '
        />
      );
    default:
      return <></>;
  }
}
export const getShopError = (shopDetails?: ShopDetailsType): JSX.Element => {
  const { shopCurrentStatus, withPackage } = shopDetails ?? {};
  if (_.isEmpty(shopDetails?.shopId)) {
    return <NoShopAlert />;
  }

  if (shopWithoutPackage(shopCurrentStatus, withPackage)) {
    return <UpgradePackageAlert />;
  }
  if (shopCurrentStatus === SHOP_STATUS.IN_PROGRESS) {
    return <ShopInProgressAlert />;
  }
  return <></>;
};

export const getSiteDomain = (webSite?: string, shopId?: string): string => {
  if (webSite) {
    return `//${webSite}`;
  } else {
    return `//${process.env.REACT_APP_DEFAULT_SITE}?shopId=${shopId}`;
  }
};

export const getShopActionButtonTest = (currentStatus: ShopStatus, translation: any): any => {
  switch (currentStatus) {
    case SHOP_STATUS.RUNNING:
      return (
        <span className='bg-red-100 text-red-500 width-fit underline '>
          {' '}
          {translation('CANCELE_SHOP')}{' '}
        </span>
      );
    case SHOP_STATUS.CANCELED:
      return (
        <span className='bg-green-100 text-accent width-fit underline'>
          {translation('RUN_SHOP')}
        </span>
      );
    case SHOP_STATUS.IN_PROGRESS:
      return (
        <span className='bg-green-100 text-accent width-fit underline'>
          {translation('RUN_SHOP')}
        </span>
      );
    case SHOP_STATUS.NOT_STARTED:
      return (
        <span className='bg-yellow-100 text-yellow-400 width-fit underline'>
          {translation('START_SHOP')}
        </span>
      );
    case SHOP_STATUS.PAYMENT_FAILED:
      return (
        <span className='bg-yellow-100 text-yellow-400 width-fit underline'>
          {translation('START_SHOP')}
        </span>
      );
    default:
      return '';
  }
};

export const getFontAwesomeIcon = (icon: string) => {
  if (icon === 'faCreditCardAlt') {
    return faCreditCardAlt;
  } else if (icon === 'faPeopleCarryBox') {
    return faPeopleCarryBox;
  }
};

export const getSideBarItemsDisableStatus = (activeShop?: ShopDetailsType) => {
  if (
    isSuperAdmin() ||
    activeShop?.shopCurrentStatus === SHOP_STATUS.RUNNING ||
    activeShop?.shopCurrentStatus === SHOP_STATUS.TRAILER ||
    activeShop?.shopCurrentStatus === SHOP_STATUS.IN_PROGRESS
  ) {
    return false;
  } else {
    return true;
  }
};

export const shopWithoutPackage = (shopStatus?: ShopStatus, withPackage?: boolean) => {
  if (!withPackage) {
    return true;
  }
  if (
    shopStatus === SHOP_STATUS.CANCELED ||
    shopStatus === SHOP_STATUS.EXPIRED ||
    shopStatus === SHOP_STATUS.NOT_STARTED ||
    shopStatus === SHOP_STATUS.PAYMENT_FAILED
  ) {
    return true;
  }
};

export function renderOrderStatus(orderStatus?: OrderStatus) {
  const status = orderStatus?.toLocaleLowerCase();
  switch (status) {
    case ORDER_STATUS.IN_PREPARATION:
      return (
        <Badge
          textKey={`order_status_${status}`}
          className='bg-yellow-500 width-fit text-md font-semibold text-heading '
        />
      );
    case ORDER_STATUS.FAILED:
    case ORDER_STATUS.CANCELED:
      return (
        <Badge
          textKey={`order_status_${status}`}
          className='bg-red-400 text-white width-fit text-md font-semibold text-heading '
        />
      );
    case ORDER_STATUS.PENDING_SHIPPING:
      return (
        <Badge
          textKey={`order_status_${status}`}
          className='bg-blue-500  width-fit text-md font-semibold text-heading '
        />
      );
    case ORDER_STATUS.COMPLETED:
      return (
        <Badge
          textKey={`order_status_${status}`}
          className='bg-accent text-white width-fit text-md font-semibold text-heading '
        />
      );
    default:
      return <></>;
  }
}
